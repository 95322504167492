import type { CustomMenuClass } from 'markets-store'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение инстанса кастом меню
 * @example
 * const customMenu = useCustomMenu()
 */
export function useCustomMenu(): Ref<CustomMenuClass> {
  const MS = useMarketsStore()

  return ref(MS.customMenu) as Ref<CustomMenuClass>
}
